import React, {useEffect, useState, useRef} from 'react'
import {useRequest} from '../../hooks/useRequest'
import {ExportExcel} from '../../utils/ExcelExport'
import * as api from '../../api/index'
import {useCompanyDispatch} from '../../context/company/companyContext'
import {toast} from 'react-toastify'
import { useAlertState} from '../../context/alert/alertContext'
import {useLoader} from '../../hooks/useLoader'
import currencyFormatter from '../../utils/InputFormatter'

const TargetChangeButton = ({bgColor,index, updatedRow, value, focusUpdate,onBlur, onChange, name,targetKpi, disabled, isExcel, setRowIndex, setUpdatedTotal}) => {
   //const [value, setValue] = useState(readValue)
   const request = useRequest()
   const companyDispatch = useCompanyDispatch()
   const {excelImportModal} = useAlertState()
   const inputRef = useRef(null)
   const [hyperionValue,setHyperionValue] = useState()
   const [isHover,setIsHover] = useState(false)
   const setLoader = useLoader()

  //  useEffect(()=>{
  //   if(readValue) {
  //     setValue(readValue)
  //   }
  // },[])
  const getNewRows = async(resValue) => {
    if(!updatedRow) return
    setRowIndex(index)
    return await updatedRow.map((item,i) => {
        if (i == index) {
            return {
                ...item,
                [name]:resValue
            }
        }
        else {
            return {...item}
        }
      })
  }
  const updateTable = async(resValue) => {
    try {
        const newRows = await getNewRows(resValue)
        const updatePost = await api.updatePostDataCardCompanyDetail(JSON.stringify(newRows[index])) 
        setUpdatedTotal(prev => ({...prev,ceoTotalScore: updatePost.data.result.companyCard.ceoTotalScore,occuredBonusScore: updatePost.data.result.companyCard.occuredBonusScore,totalScore:updatePost.data.result.companyCard.totalScore}))

        companyDispatch({type: "COMPANYCARDUPDATEDATADETAIL", payload: updatePost.data.result})
        toast.success('Güncelleme İşlemi Başarılı')
    } catch (error) {
 
    }
  }
useEffect(() => {
    if ((excelImportModal.data || excelImportModal.data == 0) && excelImportModal.data != value && excelImportModal.index == index && excelImportModal.name == name) {
       updateTable(excelImportModal.data)
    }
},[excelImportModal])

const onMouseLeave = () => setIsHover(false)
const getHyperionData = () => {
    setLoader(true)
    const data = {
        companyId: targetKpi.companyId,
        periodId: targetKpi.periodId,
        targetKPIId: targetKpi.kpi.id,
        unitId: targetKpi.unitId,
        oldPeriod :targetKpi.oldPeriod,
        scenario:targetKpi.scenario
    }
    request('/Hyperion/HyperionValue',{method:'POST',data:JSON.stringify(data)}).then(res=>{
        if ((res.data || res.data == 0) && res.data != '' ) {
            console.log(res,'resss')
            updateTable(res.data)
            setHyperionValue(currencyFormatter(res.data))
            if (inputRef.current) {
                inputRef.current.value = currencyFormatter(res.data)
            }
        }else {
            toast.info("Hypreion Verisi Bulunamadı")  
        }
    }).finally(() => setLoader(false))
}

if (targetKpi?.kpi?.type == 'Excel' && isExcel) {
    return <>
    {   disabled ? <div className='changed-button' onMouseEnter={() => setIsHover(true)}>
            <input ref={inputRef} disabled={disabled} name={name} style={{backgroundColor: bgColor ? bgColor : ''}}  type="text" value={value}/>
        </div>
        : value
        ? !isHover ? <div className='changed-button' onMouseEnter={() => setIsHover(true)}>
            <input ref={inputRef} readOnly name={name} style={{backgroundColor: bgColor ? bgColor : ''}}  type="text" value={value}/>
        </div>: <ExportExcel className='excel-button' index={index} tag={targetKpi?.kpi?.name} name={name} mouseLeave={onMouseLeave}/>
        :<ExportExcel className='excel-button' index={index} tag={targetKpi?.kpi?.name} name={name} mouseLeave={onMouseLeave}/>
    }
    </>
}
if (targetKpi?.kpi?.type == 'Hyperion') {
    return <div className='changed-button'>
        {   disabled ? <div className='changed-button' onMouseEnter={() => setIsHover(true)}>
            <input ref={inputRef} disabled={disabled} name={name} style={{backgroundColor: bgColor ? bgColor : ''}}  type="text" value={value}/>
            </div>
            : hyperionValue || value
            ? !isHover ? <input ref={inputRef} name={name} onMouseEnter={() => setIsHover(true)} style={{backgroundColor: bgColor ? bgColor : ''}} readOnly type="text" value={hyperionValue || value} />
            : <button onMouseLeave={() => setIsHover(false)} className='changed-button--button' onClick={getHyperionData}>Verileri Al</button>
            : <button  onMouseLeave={() => setIsHover(false)}  className='changed-button--button' onClick={getHyperionData}>Verileri Al</button>
        }
    </div>

}
  return (
    <div className='changed-button'>
        <input disabled={disabled} onBlur={onBlur} onFocus={focusUpdate} name={name} style={{backgroundColor: bgColor ? bgColor : ''}} onChange={onChange}  type="text" key={value}  defaultValue={value} />
    </div>
  )
}

export default TargetChangeButton